import { GroupUser } from '../user/groupuser';
import { DateTime } from 'luxon';

export class Project {
  ishtarProjectId?: string;
  projectName?: string;
  projectId?: string;
  client?: { id?: string; name?: string };
  status?: { id?: string; name?: string };
  description?: string;
  preStudy?: boolean;
  calculateProgress?: boolean;
  startDate?: Date;
  deadline?: Date;
  projectType?: { id?: string; name?: string };
  projectVersion?: string;
  projectChannel?: string;
  progress?: number;
  productOwner?: GroupUser;
  owners?: GroupUser[];
  members?: GroupUser[];
  discounts?: { id: string; name?: string }[];
  parent?: Project;
  isTemplate?: boolean;
  id?: string;
  name?: string;

  constructor(obj: Partial<Project>) {
    Object.assign(this, obj);
    if (typeof this.startDate == 'string') {
      this.startDate = DateTime.fromFormat(this.startDate, 'd/M/yyyy H:m', {
        zone: 'utc',
      }).toJSDate();
    }

    if (typeof this.deadline == 'string') {
      this.deadline = DateTime.fromFormat(this.deadline, 'd/M/yyyy H:m', {
        zone: 'utc',
      }).toJSDate();
    }
  }
}
