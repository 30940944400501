import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarProjectService } from '../../services/project.service';
import {
  addDiscounts,
  updateDiscounts,
  removeDiscounts,
  addDiscountsResolved,
  updatedDiscountsResolved,
  removeDiscountsResolved,
} from './projectMVP.actions';
import {
  addProjectMVPLines,
  addProjectMVPLinesResolved,
  getDiscounts,
  getDiscountsResolved,
  getProjectMVPLines,
  getProjectMVPLinesResolved,
  getProjectMVPsByProjectId,
  getProjectMVPsByProjectIdResolved,
  removeProjectMVPLines,
  removeProjectMVPLinesResolved,
  updateProjectMVPLines,
  updateProjectMVPLinesResolved,
} from './projectMVP.actions';
import {
  addProjectMVPs,
  addProjectMVPsResolved,
  getProjectMVPs,
  getProjectMVPsResolved,
  removeProjectMVPs,
  removeProjectMVPsResolved,
  updateProjectMVPs,
  updateProjectMVPsResolved,
} from './projectMVP.actions';

@Injectable({ providedIn: 'root' })
export class ProjectMVPEffects {
  constructor(
    private actions$: Actions,
    private ishtarProjectService: IshtarProjectService
  ) {}

  getProjectMVPs = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectMVPs),
      switchMap(({ projectId, callback }) =>
        this.ishtarProjectService.getProjectMVPs(projectId).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((projectMVPs) =>
            of(
              getProjectMVPsResolved({
                projectMVPs,
              })
            )
          ),
          catchError((e) => [])
        )
      )
    )
  );

  getProjectMVPsByProjectId = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectMVPsByProjectId),
      switchMap(({ projectId, callback }) =>
        this.ishtarProjectService.getProjectMVPsByProjectId(projectId).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((result) =>
            of(
              getProjectMVPsByProjectIdResolved({
                projectMVPs: result.projectMVPs,
                projectMVPLines: result.projectMVPLines,
              })
            )
          ),
          catchError((e) => [])
        )
      )
    )
  );

  addProjectMVPs = createEffect(() =>
    this.actions$.pipe(
      ofType(addProjectMVPs),
      mergeMap(({ projectMVPs, callback, error }) =>
        this.ishtarProjectService.addProjectMVPs(projectMVPs).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedProjectMVPs) =>
            of(
              addProjectMVPsResolved({
                addedProjectMVPs,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateProjectMVPs = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProjectMVPs),
      mergeMap(({ projectMVPs, callback, error }) =>
        this.ishtarProjectService.updateProjectMVPs(projectMVPs).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedProjectMVPs) =>
            of(
              updateProjectMVPsResolved({
                updatedProjectMVPs,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeProjectMVPs = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProjectMVPs),
      mergeMap(({ ids, callback, error }) =>
        this.ishtarProjectService.removeProjectMVPs(ids).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((ishtarProjectMVPIds) =>
            of(
              removeProjectMVPsResolved({
                ishtarProjectMVPIds,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getProjectMVPLines = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectMVPLines),
      switchMap(({ projectMVPIds, callback }) =>
        this.ishtarProjectService.getProjectMVPLines(projectMVPIds).pipe(
          switchMap((projectMVPLines) =>
            of(
              getProjectMVPLinesResolved({
                projectMVPLines,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addProjectMVPLines = createEffect(() =>
    this.actions$.pipe(
      ofType(addProjectMVPLines),
      mergeMap(({ projectMVPLines, callback, error }) =>
        this.ishtarProjectService.addProjectMVPLines(projectMVPLines).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedProjectMVPLines) =>
            of(
              addProjectMVPLinesResolved({
                addedProjectMVPLines,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateProjectMVPLines = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProjectMVPLines),
      mergeMap(({ projectMVPLines, callback, error }) =>
        this.ishtarProjectService.updateProjectMVPLines(projectMVPLines).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedProjectMVPLines) =>
            of(
              updateProjectMVPLinesResolved({
                updatedProjectMVPLines,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeProjectMVPLines = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProjectMVPLines),
      mergeMap(({ ids, callback, error }) =>
        this.ishtarProjectService.removeProjectMVPLines(ids).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((ishtarProjectMVPLineIds) =>
            of(
              removeProjectMVPLinesResolved({
                ishtarProjectMVPLineIds,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getDiscounts = createEffect(() =>
    this.actions$.pipe(
      ofType(getDiscounts),
      switchMap(({ callback }) =>
        this.ishtarProjectService.getDiscounts().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((discounts) =>
            of(
              getDiscountsResolved({
                discounts,
              })
            )
          ),
          catchError((e) => [])
        )
      )
    )
  );

  addDiscounts = createEffect(() =>
    this.actions$.pipe(
      ofType(addDiscounts),
      mergeMap(({ discounts, callback, error }) =>
        this.ishtarProjectService.addDiscounts(discounts).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedDiscounts) =>
            of(
              addDiscountsResolved({
                addedDiscounts: addedDiscounts,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateDiscounts = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDiscounts),
      mergeMap(({ discounts, callback, error }) =>
        this.ishtarProjectService.updateDiscounts(discounts).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedDiscounts) =>
            of(
              updatedDiscountsResolved({
                updatedDiscounts: updatedDiscounts,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeDiscounts = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDiscounts),
      mergeMap(({ ids, callback, error }) =>
        this.ishtarProjectService.removeDiscounts(ids).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((ishtarDiscountIds) =>
            of(
              removeDiscountsResolved({
                ishtarDiscountIds: ishtarDiscountIds,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
