export class Discount {
  ishtarDiscountId?: string;
  reason!: string;
  value!: number;
  percent!: boolean;

  constructor(obj: Partial<Discount>) {
    Object.assign(this, obj);
  }
}
