import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import { ProjectMVPBlockTemplateLine } from '../../models/project/projectMVPBlockTemplateLine';
import {
  getProjectMVPBlockTemplateLinesResolved,
  removeProjectMVPBlockTemplateLinesResolved,
  updateProjectMVPBlockTemplateLinesResolved,
  addProjectMVPBlockTemplateLinesResolved,
} from './projectMVPBlockTemplateLine.actions';

export const featureSlice = 'projectMVPBlockTemplateLine';

export interface State {
  projectMVPBlockTemplateLines?: ProjectMVPBlockTemplateLine[];
  projectMVPBlockTemplateLine?: ProjectMVPBlockTemplateLine;
  ishtarProjectMVPBlockTemplateLineids?: string[];
}
const defaultState: State = {
  projectMVPBlockTemplateLines: undefined,
  projectMVPBlockTemplateLine: undefined,
  ishtarProjectMVPBlockTemplateLineids: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return projectMVPBlockTemplateLineReducer(state, action);
}

export const initialState: State = defaultState;

export const projectMVPBlockTemplateLineReducer = createReducer(
  initialState,
  on(
    getProjectMVPBlockTemplateLinesResolved,
    (state, { projectMVPBlockTemplateLines }) => ({
      ...state,
      projectMVPBlockTemplateLines: [...projectMVPBlockTemplateLines],
    })
  ),
  on(
    removeProjectMVPBlockTemplateLinesResolved,
    (state, { ishtarProjectMVPBlockTemplateLineIds }) => ({
      ...state,
      projectMVPBlockTemplateLines: state.projectMVPBlockTemplateLines?.filter(
        (p) =>
          !ishtarProjectMVPBlockTemplateLineIds.includes(
            p.ishtarProjectMVPBlockTemplateLineId!
          )
      ),
    })
  ),
  on(
    updateProjectMVPBlockTemplateLinesResolved,
    (state, { updatedProjectMVPBlockTemplateLines }) => ({
      ...state,
      projectMVPBlockTemplateLines: state.projectMVPBlockTemplateLines?.map(
        (p) =>
          updatedProjectMVPBlockTemplateLines.find(
            (u) =>
              p.ishtarProjectMVPBlockTemplateLineId ===
              u.ishtarProjectMVPBlockTemplateLineId
          ) ?? p
      ),
    })
  ),
  on(
    addProjectMVPBlockTemplateLinesResolved,
    (state, { addedProjectMVPBlockTemplateLines }) => ({
      ...state,
      projectMVPBlockTemplateLines: addedProjectMVPBlockTemplateLines.concat([
        ...(state.projectMVPBlockTemplateLines?? []),
      ]),
    })
  )
);

export const projectMVPBlockTemplateLineState = (state: AppState) =>
  state.coreFeature.projectMVPBlockTemplateLine;
