import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarProjectService } from '../../services/project.service';
import {
  addProject,
  addProjectResolved,
  getProject,
  getProjectResolved,
  getProjects,
  getProjectsResolved,
  updateProject,
  updateProjectResolved,
  removeProject,
  removeProjectResolved,
  addProjectTemplateResolved,
} from './project.actions';
import {
  onProjectDeletedMVPActionResolved,
  removeDiscountsResolved,
} from '../projectMVP/projectMVP.actions';
import { onProjectDeletedEstimationActionResolved } from '../projectEstimation/projectEstimation.actions';

@Injectable({ providedIn: 'root' })
export class ProjectEffects {
  constructor(
    private actions$: Actions,
    private ishtarProjectService: IshtarProjectService
  ) {}

  getProjects = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjects),
      mergeMap(({ callback }) =>
        this.ishtarProjectService.getProjects().pipe(
          tap((projects) => (callback ? callback() : undefined)),
          switchMap((projects) => of(getProjectsResolved({ projects }))),
          catchError((e) => [])
        )
      )
    )
  );

  getProject = createEffect(() =>
    this.actions$.pipe(
      ofType(getProject),
      mergeMap(({ ishtarProjectId, callback }) =>
        this.ishtarProjectService.getProject(ishtarProjectId).pipe(
          tap((project) => (callback ? callback(project) : undefined)),
          switchMap((project) => of(getProjectResolved({ project }))),
          catchError((e) => [])
        )
      )
    )
  );

  addProject = createEffect(() =>
    this.actions$.pipe(
      ofType(addProject),
      switchMap(
        ({
          project,
          callback,
          error,
          createProjectChannel,
          createdFromTemplate,
        }) =>
          this.ishtarProjectService
            .addProject(project, createProjectChannel, createdFromTemplate)
            .pipe(
              tap((project) => (callback ? callback(project) : undefined)),
              switchMap((addedProject) =>
                addedProject.isTemplate
                  ? of(addProjectTemplateResolved({ project: addedProject }))
                  : of(addProjectResolved({ addedProject }))
              ),
              catchError((e) => {
                if (error) error(e);
                return [];
              })
            )
      )
    )
  );

  updateProject = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProject),
      switchMap(({ project, callback, error, createProjectChannel }) =>
        this.ishtarProjectService
          .updateProject(project, createProjectChannel)
          .pipe(
            tap((project) => (callback ? callback(project) : undefined)),
            switchMap((project) =>
              of(updateProjectResolved({ project: project }))
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  removeProject = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProject),
      switchMap(({ ishtarProjectId, callback, error }) =>
        this.ishtarProjectService.removeProject(ishtarProjectId).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((result) => {
            const actions: any[] = [
              removeProjectResolved({
                ishtarProjectIds: [result.deletedProjectId],
              }),
              onProjectDeletedMVPActionResolved({
                deletedProjectId: result.deletedProjectId,
              }),
              onProjectDeletedEstimationActionResolved({
                deletedProjectId: result.deletedProjectId,
              }),
            ];
            if (result.deletedDiscountIds) {
              actions.push(
                removeDiscountsResolved({
                  ishtarDiscountIds: result.deletedDiscountIds,
                })
              );
            }
            return actions;
          }),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
