import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import { ProjectTypeLine } from '../../models/project/projectTypeLine';
import {
  getProjectTypeLinesResolved,
  removeProjectTypeLinesResolved,
  updateProjectTypeLinesResolved,
  addProjectTypeLinesResolved,
  projectTypeDeleteActionResolved,
} from './project-type-line.actions';

export const featureSlice = 'projectTypeLine';

export interface State {
  projectTypeLines?: ProjectTypeLine[];
  projectTypeLine?: ProjectTypeLine;
  ishtarProjectTypeLineIds?: string[];
}
const defaultState: State = {
  projectTypeLines: undefined,
  projectTypeLine: undefined,
  ishtarProjectTypeLineIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return projectTypeLineReducer(state, action);
}

export const initialState: State = defaultState;

export const projectTypeLineReducer = createReducer(
  initialState,
  on(getProjectTypeLinesResolved, (state, { projectTypeLines }) => ({
    ...state,
    projectTypeLines: [...projectTypeLines],
  })),
  on(removeProjectTypeLinesResolved, (state, { ishtarProjectTypeLineIds }) => ({
    ...state,
    projectTypeLines: state.projectTypeLines?.filter(
      (p) => !ishtarProjectTypeLineIds.includes(p.ishtarProjectTypeLineId!)
    ),
  })),
  on(updateProjectTypeLinesResolved, (state, { updatedProjectTypeLines }) => ({
    ...state,
    projectTypeLines: state.projectTypeLines?.map(
      (p) =>
        updatedProjectTypeLines.find(
          (u) => p.ishtarProjectTypeLineId === u.ishtarProjectTypeLineId
        ) ?? p
    ),
  })),
  on(addProjectTypeLinesResolved, (state, { addedProjectTypeLines }) => ({
    ...state,
    projectTypeLines: addedProjectTypeLines.concat([
      ...(state.projectTypeLines ?? []),
    ]),
  })),
  on(projectTypeDeleteActionResolved, (state, { deletedProjectTypeId }) => ({
    ...state,
    projectTypeLines: state.projectTypeLines?.filter(
      (p) => p.projectType?.id !== deletedProjectTypeId
    ),
  }))
);

export const projectTypeLineState = (state: AppState) =>
  state.coreFeature.projectTypeLine;
