import { createAction, props } from '@ngrx/store';
import { ProjectEstimationParam } from '../../models/project/projectEstimationParam';

export const getProjectEstimationParams = createAction(
  '[ProjectEstimationParam] Get estimationParams',
  props<{
    callback?: () => void;
  }>()
);

export const getProjectEstimationParamsResolved = createAction(
  '[ProjectEstimationParam] Get projectEstimationParams resolved',
  props<{
    projectEstimationParams: ProjectEstimationParam[];
  }>()
);

export const removeProjectEstimationParams = createAction(
  '[ProjectEstimationParam] Remove projectEstimationParams',
  props<{ ids: string[]; callback?: () => void }>()
);

export const removeProjectEstimationParamsResolved = createAction(
  '[ProjectEstimationParam] Remove projectEstimationParams resolved',
  props<{ ishtarProjectEstimationParamIds: string[] }>()
);

export const updateProjectEstimationParams = createAction(
  '[ProjectEstimationParam] Update projectEstimationParams',
  props<{
    projectEstimationParams: ProjectEstimationParam[];
    callback?: () => void;
  }>()
);

export const updateProjectEstimationParamsResolved = createAction(
  '[ProjectEstimationParam] Update projectEstimationParams resolved',
  props<{ updatedProjectEstimationParams: ProjectEstimationParam[] }>()
);

export const addProjectEstimationParams = createAction(
  '[ProjectEstimationParam] Add projectEstimationParams',
  props<{
    projectEstimationParams: ProjectEstimationParam[];
    callback?: (addedProjectEstimationParams: ProjectEstimationParam[]) => void;
  }>()
);

export const addProjectEstimationParamsResolved = createAction(
  '[ProjectEstimationParam] Add projectEstimationParams resolved',
  props<{ addedProjectEstimationParams: ProjectEstimationParam[] }>()
);
