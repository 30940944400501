import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import { ProjectEstimation } from '../../models/project/projectEstimation';
import {
  getProjectEstimationsResolved,
  removeProjectEstimationsResolved,
  updateProjectEstimationsResolved,
  addProjectEstimationsResolved,
  onProjectDeletedEstimationActionResolved,
} from './projectEstimation.actions';

export const featureSlice = 'projectEstimation';

export interface State {
  projectEstimations: ProjectEstimation[];
  projectEstimation?: ProjectEstimation;
  ishtarProjectEstimationIds?: string[];
}
const defaultState: State = {
  projectEstimations: [],
  projectEstimation: undefined,
  ishtarProjectEstimationIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return projectEstimationReducer(state, action);
}

export const initialState: State = defaultState;

export const projectEstimationReducer = createReducer(
  initialState,
  on(getProjectEstimationsResolved, (state, { projectEstimations }) => ({
    ...state,
    projectEstimations: [...projectEstimations],
  })),
  on(
    removeProjectEstimationsResolved,
    (state, { ishtarProjectEstimationIds }) => ({
      ...state,
      projectEstimations: state.projectEstimations.filter(
        (p) =>
          !ishtarProjectEstimationIds.includes(p.ishtarProjectEstimationId!)
      ),
    })
  ),
  on(
    updateProjectEstimationsResolved,
    (state, { updatedProjectEstimations }) => ({
      ...state,
      projectEstimations: state.projectEstimations.map(
        (p) =>
          updatedProjectEstimations.find(
            (u) => p.ishtarProjectEstimationId === u.ishtarProjectEstimationId
          ) ?? p
      ),
    })
  ),
  on(addProjectEstimationsResolved, (state, { addedProjectEstimations }) => ({
    ...state,
    projectEstimations: addedProjectEstimations.concat([
      ...state.projectEstimations,
    ]),
  })),
  on(
    onProjectDeletedEstimationActionResolved,
    (state, { deletedProjectId }) => ({
      ...state,
      projectEstimations: state.projectEstimations.filter(
        (p) => p.project.id !== deletedProjectId
      ),
    })
  )
);

export const projectEstimationState = (state: AppState) =>
  state.coreFeature.projectEstimation;
