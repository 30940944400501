import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import { Product } from '../../models/project/product';
import {
  getProductsResolved,
  removeProductResolved,
  updateProductsResolved,
  addProductsResolved,
  getQuantityUnitsResolved,
  removeQuantityUnitResolved,
  addQuantityUnitsResolved,
  updateQuantityUnitsResolved,
} from './product.actions';
import { QuantityUnit } from '../../models/project/quantityUnit';

export const featureSlice = 'product';

export interface State {
  products?: Product[];
  product?: Product;
  ishtarProductIds?: string[];
  quantityUnits?: QuantityUnit[];
  quantityUnit?: QuantityUnit;
  ishtarQuantityUnitIds?: string[];
}
const defaultState: State = {
  products: undefined,
  product: undefined,
  ishtarProductIds: [],
  quantityUnits: undefined,
  quantityUnit: undefined,
  ishtarQuantityUnitIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return productReducer(state, action);
}

export const initialState: State = defaultState;

export const productReducer = createReducer(
  initialState,
  on(getProductsResolved, (state, { products }) => ({
    ...state,
    products: [...products],
  })),
  on(removeProductResolved, (state, { ishtarProductId }) => ({
    ...state,
    products: state.products?.filter(
      (p) => p.ishtarProjectsProductId !== ishtarProductId
    ),
  })),
  on(updateProductsResolved, (state, { updatedProducts }) => ({
    ...state,
    products: state.products?.map(
      (p) =>
        updatedProducts.find(
          (u) => p.ishtarProjectsProductId === u.ishtarProjectsProductId
        ) ?? p
    ),
  })),
  on(addProductsResolved, (state, { addedProducts }) => ({
    ...state,
    products: addedProducts.concat([...(state.products ?? [])]),
  })),
  on(getQuantityUnitsResolved, (state, { quantityUnits }) => ({
    ...state,
    quantityUnits: [...quantityUnits],
  })),
  on(removeQuantityUnitResolved, (state, { ishtarQuantityUnitIds }) => ({
    ...state,
    quantityUnits: state.quantityUnits?.filter(
      (p) => !ishtarQuantityUnitIds.includes(p.ishtarQuantityUnitId!)
    ),
    products: state.products?.map((p) => ({
      ...p,
      definedUnits: p.definedUnits?.filter(
        (q) => !ishtarQuantityUnitIds.includes(q.id!)
      ),
    })),
  })),
  on(addQuantityUnitsResolved, (state, { addedQuantityUnits }) => ({
    ...state,
    quantityUnits: addedQuantityUnits.concat([...(state.quantityUnits ?? [])]),
  })),
  on(updateQuantityUnitsResolved, (state, { updatedQuantityUnits }) => ({
    ...state,
    quantityUnits: state.quantityUnits?.map(
      (p) =>
        updatedQuantityUnits.find(
          (u) => p.ishtarQuantityUnitId === u.ishtarQuantityUnitId
        ) ?? p
    ),
  }))
);

export const productState = (state: AppState) => state.coreFeature.product;
