import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarProjectService } from '../../services/project.service';
import {
  addSkills,
  addSkillsResolved,
  getSkills,
  getSkillsResolved,
  removeSkill,
  removeSkillResolved,
  updateSkills,
  updateSkillsResolved,
} from './skill.actions';
import { removeProjectTypeLinesResolved } from '../project-type-line/project-type-line.actions';
import { removeProjectMVPBlockTemplateLinesResolved } from '../projectMVPBlockTemplateLine/projectMVPBlockTemplateLine.actions';
@Injectable({ providedIn: 'root' })
export class SkillEffects {
  constructor(
    private actions$: Actions,
    private ishtarProjectService: IshtarProjectService
  ) {}

  getSkills = createEffect(() =>
    this.actions$.pipe(
      ofType(getSkills),
      switchMap(({ callback }) =>
        this.ishtarProjectService.getSkills().pipe(
          switchMap((skills) =>
            of(
              getSkillsResolved({
                skills,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addSkills = createEffect(() =>
    this.actions$.pipe(
      ofType(addSkills),
      switchMap(({ skills, callback, error }) =>
        this.ishtarProjectService.addSkills(skills).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedSkills) => of(addSkillsResolved({ addedSkills }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateSkills = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSkills),
      switchMap(({ skills, callback, error }) =>
        this.ishtarProjectService.updateSkills(skills).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedSkills) =>
            of(updateSkillsResolved({ updatedSkills }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeSkill = createEffect(() =>
    this.actions$.pipe(
      ofType(removeSkill),
      switchMap(({ skillId, callback, error }) =>
        this.ishtarProjectService.removeSkill(skillId).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((result) => {
            const actions: any[] = [];
            if (result.deletedProjectTypeLineIds)
              actions.push(
                removeProjectTypeLinesResolved({
                  ishtarProjectTypeLineIds: result.deletedProjectTypeLineIds,
                })
              );
            if (result.deletedProjectMVPBlockTemplateLineIds)
              actions.push(
                removeProjectMVPBlockTemplateLinesResolved({
                  ishtarProjectMVPBlockTemplateLineIds:
                    result.deletedProjectMVPBlockTemplateLineIds,
                })
              );
            actions.push(
              removeSkillResolved({
                ishtarSkillId: result.deletedSkillId,
              })
            );
            return actions;
          }),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
