import { createAction, props } from '@ngrx/store';
import { ProjectMVP } from '../../models/project/projectMVP';
import { ProjectMVPLine } from '../../models/project/projectMVPLine';
import { getProject } from '../project/project.actions';
import { Discount } from '../../models/project/discount';
import { ActionBase } from '../action-base.interface';

export const getProjectMVPs = createAction(
  '[ProjectMVPs] Get projectMVPs',
  props<{
    projectId: string;
    callback?: (projectMVPS: ProjectMVP[]) => void;
  }>()
);

export const getProjectMVPsResolved = createAction(
  '[ProjectMVPs] Get projectMVPs resolved',
  props<{
    projectMVPs: ProjectMVP[];
  }>()
);

export const getProjectMVPsByProjectId = createAction(
  '[ProjectMVPs] Get projectMVPs by projectId',
  props<{
    projectId: string;
    callback?: (result: {
      projectMVPs: ProjectMVP[];
      projectMVPLines: ProjectMVPLine[];
    }) => void;
  }>()
);

export const getProjectMVPsByProjectIdResolved = createAction(
  '[ProjectMVPs] Get projectMVPs by projectId resolved',
  props<{
    projectMVPs: ProjectMVP[];
    projectMVPLines: ProjectMVPLine[];
  }>()
);

export const removeProjectMVPs = createAction(
  '[ProjectMVPs] Remove projectMVPs',
  props<{ ids: string[] } & ActionBase<string[]>>()
);

export const removeProjectMVPsResolved = createAction(
  '[ProjectMVPs] Remove projectMVPs resolved',
  props<{ ishtarProjectMVPIds: string[] }>()
);

export const updateProjectMVPs = createAction(
  '[ProjectMVPs] Update projectMVPs',
  props<
    {
      projectMVPs: ProjectMVP[];
    } & ActionBase<ProjectMVP[]>
  >()
);

export const updateProjectMVPsResolved = createAction(
  '[ProjectMVPs] Update projectMVPs resolved',
  props<{ updatedProjectMVPs: ProjectMVP[] }>()
);

export const addProjectMVPs = createAction(
  '[ProjectMVPs] Add projectMVPs',
  props<
    {
      projectMVPs: ProjectMVP[];
    } & ActionBase<ProjectMVP[]>
  >()
);

export const addProjectMVPsResolved = createAction(
  '[ProjectMVPs] Add projectMVPs resolved',
  props<{
    addedProjectMVPs: ProjectMVP[];
  }>()
);

export const getProjectMVPLines = createAction(
  '[ProjectMVPLine] Get projectMVPLines',
  props<{
    projectMVPIds: string[];
    callback?: () => void;
  }>()
);

export const getProjectMVPLinesResolved = createAction(
  '[ProjectMVPLines] Get projectMVPLines resolved',
  props<{
    projectMVPLines: ProjectMVPLine[];
  }>()
);

export const removeProjectMVPLines = createAction(
  '[ProjectMVPLines] Remove projectMVPLines',
  props<{ ids: string[] } & ActionBase<string[]>>()
);

export const removeProjectMVPLinesResolved = createAction(
  '[ProjectMVPLines] Remove projectMVPLines resolved',
  props<{ ishtarProjectMVPLineIds: string[] }>()
);

export const updateProjectMVPLines = createAction(
  '[ProjectMVPLines] Update projectMVPLines',
  props<
    {
      projectMVPLines: ProjectMVPLine[];
    } & ActionBase<ProjectMVPLine[]>
  >()
);

export const updateProjectMVPLinesResolved = createAction(
  '[ProjectMVPLines] Update projectMVPLine resolved',
  props<{
    updatedProjectMVPLines: ProjectMVPLine[];
  }>()
);

export const addProjectMVPLines = createAction(
  '[ProjectMVPLines] Add projectMVPLines',
  props<
    {
      projectMVPLines: ProjectMVPLine[];
    } & ActionBase<ProjectMVPLine[]>
  >()
);

export const addProjectMVPLinesResolved = createAction(
  '[ProjectMVPLines] Add projectMVPLines resolved',
  props<{ addedProjectMVPLines: ProjectMVPLine[] }>()
);

export const getDiscounts = createAction(
  '[ProjectMVPs] Get discounts',
  props<{
    callback?: (discounts: Discount[]) => void;
  }>()
);

export const getDiscountsResolved = createAction(
  '[ProjectMVPs] Get discounts resolved',
  props<{
    discounts: Discount[];
  }>()
);

export const addDiscounts = createAction(
  '[ProjectMVPs] Add discounts',
  props<
    {
      discounts: Discount[];
    } & ActionBase<Discount[]>
  >()
);

export const addDiscountsResolved = createAction(
  '[ProjectMVPs] Add discounts resolved',
  props<{
    addedDiscounts: Discount[];
  }>()
);

export const updateDiscounts = createAction(
  '[ProjectMVPs] Update discounts',
  props<
    {
      discounts: Discount[];
    } & ActionBase<Discount[]>
  >()
);
export const updatedDiscountsResolved = createAction(
  '[ProjectMVPs] Update discounts resolved',
  props<{
    updatedDiscounts: Discount[];
  }>()
);

export const removeDiscounts = createAction(
  '[ProjectMVPs] Remove discounts',
  props<
    {
      ids: string[];
    } & ActionBase<string[]>
  >()
);

export const removeDiscountsResolved = createAction(
  '[ProjectMVPs] Remove discounts resolved',
  props<{
    ishtarDiscountIds: string[];
  }>()
);

export const onProjectDeletedMVPActionResolved = createAction(
  '[ProjectMVPs] Project deleted mvp action resolved',
  props<{
    deletedProjectId: string;
  }>()
);
