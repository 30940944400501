import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import { ProjectMVP } from '../../models/project/projectMVP';
import {
  getProjectMVPsResolved,
  removeProjectMVPsResolved,
  updateProjectMVPsResolved,
  addProjectMVPsResolved,
  getProjectMVPLinesResolved,
  removeProjectMVPLinesResolved,
  updateProjectMVPLinesResolved,
  addProjectMVPLinesResolved,
  getProjectMVPsByProjectIdResolved,
  getDiscountsResolved,
  addDiscountsResolved,
  updateDiscounts,
  removeDiscountsResolved,
  onProjectDeletedMVPActionResolved,
} from './projectMVP.actions';
import { ProjectMVPLine } from '../../models/project/projectMVPLine';
import { Discount } from '../../models/project/discount';

export const featureSlice = 'projectMVP';

export interface State {
  projectMVPs: ProjectMVP[];
  projectMVP?: ProjectMVP;
  ishtarProjectMVPids?: string[];
  projectMVPLines: ProjectMVPLine[];
  projectMVPLine?: ProjectMVPLine;
  ishtarProjectMVPLineids?: string[];
  discounts: Discount[];
  discount?: Discount;
  ishtarDiscountIds?: string[];
}
const defaultState: State = {
  projectMVPs: [],
  projectMVP: undefined,
  ishtarProjectMVPids: [],
  projectMVPLines: [],
  projectMVPLine: undefined,
  ishtarProjectMVPLineids: [],
  discounts: [],
  discount: undefined,
  ishtarDiscountIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return projectMVPReducer(state, action);
}

export const initialState: State = defaultState;

export const projectMVPReducer = createReducer(
  initialState,
  on(getProjectMVPsResolved, (state, { projectMVPs }) => ({
    ...state,
    projectMVPs: [...state.projectMVPs]
      .filter(
        (mvps) =>
          !projectMVPs
            .map((p) => p.ishtarProjectMVPId)
            .includes(mvps.ishtarProjectMVPId!)
      )
      .concat([...projectMVPs]),
  })),
  on(removeProjectMVPsResolved, (state, { ishtarProjectMVPIds }) => ({
    ...state,
    projectMVPs: state.projectMVPs.filter(
      (p) => !ishtarProjectMVPIds.includes(p.ishtarProjectMVPId!)
    ),
    projectMVPLines: state.projectMVPLines.filter(
      (p) => !ishtarProjectMVPIds.includes(p.mVP.id!)
    ),
  })),
  on(updateProjectMVPsResolved, (state, { updatedProjectMVPs }) => ({
    ...state,
    projectMVPs: state.projectMVPs.map(
      (p) =>
        updatedProjectMVPs.find(
          (u) => p.ishtarProjectMVPId === u.ishtarProjectMVPId
        ) ?? p
    ),
  })),
  on(addProjectMVPsResolved, (state, { addedProjectMVPs }) => ({
    ...state,
    projectMVPs: addedProjectMVPs.concat([...state.projectMVPs]),
  })),
  on(getProjectMVPLinesResolved, (state, { projectMVPLines }) => ({
    ...state,
    projectMVPLines: [...state.projectMVPLines]
      .filter(
        (line) =>
          !projectMVPLines
            .map((p) => p.ishtarProjectMVPLineId)
            .includes(line.ishtarProjectMVPLineId!)
      )
      .concat([...projectMVPLines]),
  })),
  on(
    getProjectMVPsByProjectIdResolved,
    (state, { projectMVPs, projectMVPLines }) => ({
      ...state,
      projectMVPs: [...state.projectMVPs]
        .filter(
          (mvps) =>
            !projectMVPs
              .map((p) => p.ishtarProjectMVPId)
              .includes(mvps.ishtarProjectMVPId!)
        )
        .concat([...projectMVPs]),
      projectMVPLines: [...state.projectMVPLines]
        .filter(
          (line) =>
            !projectMVPLines
              .map((p) => p.ishtarProjectMVPLineId)
              .includes(line.ishtarProjectMVPLineId!)
        )
        .concat([...projectMVPLines]),
    })
  ),
  on(removeProjectMVPLinesResolved, (state, { ishtarProjectMVPLineIds }) => ({
    ...state,
    projectMVPLines: state.projectMVPLines.filter(
      (p) => !ishtarProjectMVPLineIds.includes(p.ishtarProjectMVPLineId!)
    ),
  })),
  on(updateProjectMVPLinesResolved, (state, { updatedProjectMVPLines }) => ({
    ...state,
    projectMVPLines: state.projectMVPLines.map(
      (p) =>
        updatedProjectMVPLines.find(
          (u) => p.ishtarProjectMVPLineId === u.ishtarProjectMVPLineId
        ) ?? p
    ),
  })),
  on(addProjectMVPLinesResolved, (state, { addedProjectMVPLines }) => ({
    ...state,
    projectMVPLines: addedProjectMVPLines.concat([...state.projectMVPLines]),
  })),
  on(getDiscountsResolved, (state, { discounts }) => ({
    ...state,
    discounts: [...state.discounts]
      .filter(
        (discount) =>
          !discounts
            .map((p) => p.ishtarDiscountId)
            .includes(discount.ishtarDiscountId!)
      )
      .concat([...discounts]),
  })),
  on(addDiscountsResolved, (state, { addedDiscounts }) => ({
    ...state,
    discounts: addedDiscounts.concat([...state.discounts]),
  })),
  on(updateDiscounts, (state, { discounts }) => ({
    ...state,
    discounts: state.discounts.map(
      (p) =>
        discounts.find((u) => p.ishtarDiscountId === u.ishtarDiscountId) ?? p
    ),
  })),
  on(removeDiscountsResolved, (state, { ishtarDiscountIds }) => ({
    ...state,
    discounts: state.discounts.filter(
      (p) => !ishtarDiscountIds.includes(p.ishtarDiscountId!)
    ),
  })),
  on(onProjectDeletedMVPActionResolved, (state, { deletedProjectId }) => ({
    ...state,
    projectMVPs: state.projectMVPs.filter(
      (p) => p.project.id !== deletedProjectId
    ),
  }))
);

export const projectMVPState = (state: AppState) =>
  state.coreFeature.projectMVP;
