import { createAction, props } from '@ngrx/store';
import { ProjectTypeLine } from '../../models/project/projectTypeLine';

export const getProjectTypeLines = createAction(
  '[ProjectTypeLines] Get projectTypeLines',
  props<{
    callback?: () => void;
  }>()
);

export const getProjectTypeLinesResolved = createAction(
  '[ProjectTypeLines] Get projectTypeLines resolved',
  props<{
    projectTypeLines: ProjectTypeLine[];
  }>()
);

export const removeProjectTypeLines = createAction(
  '[ProjectTypeLines] Remove projectTypeLines',
  props<{ ids: string[]; callback?: () => void }>()
);

export const removeProjectTypeLinesResolved = createAction(
  '[ProjectTypeLines] Remove projectTypeLines resolved',
  props<{ ishtarProjectTypeLineIds: string[] }>()
);

export const updateProjectTypeLines = createAction(
  '[ProjectTypeLines] Update projectTypeLines',
  props<{ projectTypeLines: ProjectTypeLine[]; callback?: () => void }>()
);

export const updateProjectTypeLinesResolved = createAction(
  '[ProjectTypeLines] Update projectTypeLines resolved',
  props<{ updatedProjectTypeLines: ProjectTypeLine[] }>()
);

export const addProjectTypeLines = createAction(
  '[ProjectTypeLines] Add projectTypeLines',
  props<{
    projectTypeLines: ProjectTypeLine[];
  }>()
);

export const addProjectTypeLinesResolved = createAction(
  '[ProjectTypeLines] Add projectTypeLines resolved',
  props<{ addedProjectTypeLines: ProjectTypeLine[] }>()
);

export const projectTypeDeleteActionResolved = createAction(
  '[ProjectTypeLines] Project type delete action resolved',
  props<{
    deletedProjectTypeId: string;
  }>()
);
