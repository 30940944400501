import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserLicenseInfo } from '../models/user/userlicenseinfo';
import { Project } from '../models/project/project';
import { map, tap } from 'rxjs';
import { GroupUser } from '../models/user/groupuser';
import { AADUser } from '../models/user/aadUser';
import { varconfig$, varlicense$ } from './startup.service';
import { Status } from '../models/project/status';
import {
  Filter,
  MicrosoftAuthenticationService,
} from 'processdelight-angular-components';
import { Skill } from '../models/project/skill';
import { Product } from '../models/project/product';
import { Rate } from '../models/project/rate';
import { ProjectType } from '../models/project/projectType';
import { Client } from '../models/project/client';
import { ProjectMVPBlockTemplate } from '../models/project/projectMVPBlockTemplate';
import { ProjectMVPBlockTemplateLine } from '../models/project/projectMVPBlockTemplateLine';
import { ProjectTypeLine } from '../models/project/projectTypeLine';
import { ProjectEstimationParam } from '../models/project/projectEstimationParam';
import { ProjectEstimationParamFixedPercent } from '../models/project/projectEstimationParamFixedPercent';
import { ProjectEstimation } from '../models/project/projectEstimation';
import { ProjectMVP } from '../models/project/projectMVP';
import { ProjectMVPLine } from '../models/project/projectMVPLine';
import { Discount } from '../models/project/discount';
import { QuantityUnit } from '../models/project/quantityUnit';
import { Config } from '../models/project/config';
import { AppInfo } from '../models/project/appInfo';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class IshtarProjectService {
  apiBase = `${environment.ishtarFunctions}/api`;
  constructor(
    private httpClient: HttpClient,
    private msal: MicrosoftAuthenticationService
  ) {}

  private createApiEndpointUrl(path: string) {
    const url = new URL(`${this.apiBase}/${path}`);
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return url.toString();
  }

  filterQuery(filters: Filter[]) {
    if (filters.length > 0) {
      const filterString =
        '&expand=Client,Status,ProjectType,Parent,Discounts&select=*,Client,Status,ProjectType,Parent,Discounts&filter=';
      return (
        filterString +
        filters
          .map((filter) => `('${filter.value}' in ${filter.columnName})`)
          .join(' and ')
      );
    } else {
      return '';
    }
  }
  orderByQuery(column: string, direction: string) {
    if (!column || !direction) return '';
    else return `&orderBy=${column} ${direction.toUpperCase()}`;
  }

  getLicense() {
    return this.httpClient.get<UserLicenseInfo>(
      this.createApiEndpointUrl(
        `license/${this.msal.tenantId}/${this.msal.email}`
      )
    );
  }

  getProjectsAppInfo() {
    return this.httpClient.get<AppInfo>(
      this.createApiEndpointUrl(`apps/Ishtar.Projects`)
    );
  }

  getCrmAppInfo() {
    return this.httpClient.get<AppInfo>(
      this.createApiEndpointUrl(`apps/Ishtar.CRM`)
    );
  }

  getStartUpData(userId: string, userEmail: string, language: string) {
    return this.httpClient.get<any>(
      this.createApiEndpointUrl(
        `ishtar/projects/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/startUpForProjects?userId=${userId}&userEmail=${userEmail}&language=${language}`
      )
    );
  }

  getProjects() {
    return this.httpClient
      .get<Project[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProject?`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projects) =>
          projects.map((p) => new Project(this.camelcaseKeys(p)))
        )
      );
  }

  addProject(
    project: Project,
    createProjectChannel?: boolean,
    createdFromTemplate?: boolean
  ) {
    const addedProject = this.capitalizeKeys(
      {
        ...project,
        startDate: project.startDate
          ? DateTime.fromJSDate(project.startDate, {
              zone: 'utc',
            }).toFormat('dd/MM/yyyy HH:mm')
          : undefined,
        deadline: project.deadline
          ? DateTime.fromJSDate(project.deadline, {
              zone: 'utc',
            }).toFormat('dd/MM/yyyy HH:mm')
          : undefined,
      },
      'Client',
      'Status',
      'ProjectType',
      'Discounts',
      'Parent'
    );

    return this.httpClient
      .post<Project>(
        this.createApiEndpointUrl(
          `ishtar/projects/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/projects?createChannel=${
            createProjectChannel ?? false
          }&createdFromTemplate=${createdFromTemplate ?? false}`
        ),
        addedProject,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(map((project) => new Project(this.camelcaseKeys(project))));
  }

  removeProject(ishtarProjectid: string) {
    return this.httpClient
      .delete<{
        deletedProjectId: string;
        deletedDiscountIds: string[] | undefined;
      }>(
        this.createApiEndpointUrl(
          `ishtar/projects/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/deleteProject/${ishtarProjectid}`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((result) => ({
          deletedProjectId: result.deletedProjectId,
          deletedDiscountIds: result.deletedDiscountIds,
        }))
      );
  }

  updateProject(project: Project, createProjectChannel?: boolean) {
    const updateProject = this.capitalizeKeys(
      {
        ...project,
        startDate: project.startDate
          ? DateTime.fromJSDate(project.startDate, {
              zone: 'utc',
            }).toFormat('dd/MM/yyyy HH:mm')
          : '',
        deadline: project.deadline
          ? DateTime.fromJSDate(project.deadline, {
              zone: 'utc',
            }).toFormat('dd/MM/yyyy HH:mm')
          : '',
        id: undefined,
        name: undefined,
      },
      'Client',
      'Status',
      'ProjectType',
      'Discounts',
      'Parent'
    );

    return this.httpClient
      .patch<Project>(
        this.createApiEndpointUrl(
          `ishtar/projects/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/updateProject?createChannel=${createProjectChannel ?? false}`
        ),
        updateProject,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(map((project) => new Project(this.camelcaseKeys(project))));
  }

  getProject(ishtarProjectId: string) {
    return this.httpClient
      .get<Project>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProject/${ishtarProjectId}?expand=Client&select=*,Client`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(map((project) => new Project(this.camelcaseKeys(project))));
  }

  getSkills() {
    return this.httpClient
      .get<Skill[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarSkill`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(map((skill) => skill.map((s) => new Skill(this.camelcaseKeys(s)))));
  }

  getLatestProjectId() {
    return this.httpClient.get<number>(
      this.createApiEndpointUrl(
        `ishtar/projects/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/latestProjectId`
      )
    );
  }

  getProjectMVPsByProjectId(projectId: string) {
    return this.httpClient
      .get<{
        projectMVPs: ProjectMVP[];
        projectMVPLines: ProjectMVPLine[];
      }>(
        this.createApiEndpointUrl(
          `ishtar/projects/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/getMVPByProjectId/${projectId}`
        )
      )
      .pipe(
        map((result) => ({
          projectMVPs: result.projectMVPs.map(
            (p) => new ProjectMVP(this.camelcaseKeys(p))
          ),
          projectMVPLines: result.projectMVPLines.map(
            (p) => new ProjectMVPLine(this.camelcaseKeys(p))
          ),
        }))
      );
  }

  updateSkills(skills: Skill[]) {
    const updatedSkills = skills.map((s) => this.capitalizeKeys(s));
    return this.httpClient
      .patch<Skill[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarSkill/records`
        ),
        updatedSkills,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((skills) => skills.map((s) => new Skill(this.camelcaseKeys(s))))
      );
  }

  removeSkill(skillId: string) {
    return this.httpClient
      .delete<{
        deletedProjectTypeLineIds: string[];
        deletedProjectMVPBlockTemplateLineIds: string[];
        deletedSkillId: string;
      }>(
        this.createApiEndpointUrl(
          `ishtar/projects/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/deleteSkill/${skillId}`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((result) => ({
          deletedProjectTypeLineIds: result.deletedProjectTypeLineIds,
          deletedProjectMVPBlockTemplateLineIds:
            result.deletedProjectMVPBlockTemplateLineIds,
          deletedSkillId: result.deletedSkillId,
        }))
      );
  }

  addSkills(skills: Skill[]) {
    const addedSkills = skills.map((s) => this.capitalizeKeys(s));
    return this.httpClient
      .post<Skill[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarSkill/records`
        ),
        addedSkills,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((skills) => skills.map((s) => new Skill(this.camelcaseKeys(s))))
      );
  }

  getProducts() {
    return this.httpClient
      .get<Product[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectsProduct?select=*,DefinedUnits&expand=DefinedUnits`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((product) => product.map((p) => new Product(this.camelcaseKeys(p))))
      );
  }

  updateProducts(products: Product[]) {
    const updatedProducts = products.map((p) =>
      this.capitalizeKeys(p, 'EAN', 'definedUnits')
    );
    return this.httpClient
      .patch<Product[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectsProduct/records`
        ),
        updatedProducts,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((products) =>
          products.map((p) => new Product(this.camelcaseKeys(p)))
        )
      );
  }

  removeProduct(productId: string) {
    return this.httpClient
      .delete<{
        deletedProjectTypeLineIds: string[];
        deletedProjectMVPBlockTemplateLineIds: string[];
        deletedProductId: string;
      }>(
        this.createApiEndpointUrl(
          `ishtar/projects/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/deleteProduct/${productId}`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((result) => ({
          deletedProjectTypeLineIds: result.deletedProjectTypeLineIds,
          deletedProjectMVPBlockTemplateLineIds:
            result.deletedProjectMVPBlockTemplateLineIds,
          deletedProductId: result.deletedProductId,
        }))
      );
  }

  addProducts(products: Product[]) {
    const addedProducts = products.map((p) =>
      this.capitalizeKeys(p, 'EAN', 'definedUnits')
    );
    return this.httpClient
      .post<Product[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectsProduct/records`
        ),
        addedProducts,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((products) =>
          products.map((p) => new Product(this.camelcaseKeys(p)))
        )
      );
  }

  getDefaultRates() {
    return this.httpClient
      .get<Rate[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarRate?expand=Product,Skill&select=*,Product,Skill&filter=Title eq DefaultRate`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(map((rate) => rate.map((r) => new Rate(this.camelcaseKeys(r)))));
  }

  addDefaultRates(rates: Rate[]) {
    const addedProducts = rates.map((r) =>
      this.capitalizeKeys(r, 'Skill', 'Product', 'QuantityUnit')
    );
    return this.httpClient
      .post<Product[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarRate/records`
        ),
        addedProducts,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((products) => products.map((p) => new Rate(this.camelcaseKeys(p))))
      );
  }

  updateDefaultRates(rates: Rate[]) {
    const updatedRates = rates.map((r) =>
      this.capitalizeKeys(
        { ...r, name: undefined, id: undefined },
        'Skill',
        'Product',
        'QuantityUnit'
      )
    );
    return this.httpClient
      .patch<Rate[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarRate/records`
        ),
        updatedRates,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(map((rates) => rates.map((r) => new Rate(this.camelcaseKeys(r)))));
  }

  removeDefaultRate(id: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarRate/records`
      ),
      {
        body: id,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  getProjectTypes() {
    return this.httpClient
      .get<ProjectType[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectType?expand=EstimationParam&select=*,EstimationParam/*`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectType) =>
          projectType.map((p) => new ProjectType(this.camelcaseKeys(p)))
        )
      );
  }

  addProjectType(projectTypes: ProjectType[]) {
    const addedProjectTypes = projectTypes.map((p) =>
      this.capitalizeKeys(p, 'EstimationParam')
    );
    return this.httpClient
      .post<ProjectType[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectType/records`
        ),
        addedProjectTypes,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectTypes) =>
          projectTypes.map((p) => new ProjectType(this.camelcaseKeys(p)))
        )
      );
  }

  updateProjectTypes(projectTypes: ProjectType[]) {
    const updatedProjectTypes = projectTypes.map((p) =>
      this.capitalizeKeys(p, 'EstimationParam')
    );
    return this.httpClient
      .patch<ProjectType[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectType/records`
        ),
        updatedProjectTypes,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectTypes) =>
          projectTypes.map((p) => new ProjectType(this.camelcaseKeys(p)))
        )
      );
  }

  removeProjectType(ishtarProjectTypeid: string) {
    return this.httpClient
      .delete<{
        deletedProjectTypeId: string;
        deletedEstimationParamId: string | undefined;
      }>(
        this.createApiEndpointUrl(
          `ishtar/projects/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/deleteProjectType/${ishtarProjectTypeid}`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((result) => ({
          deletedProjectTypeId: result.deletedProjectTypeId,
          deletedEstimationParamId: result.deletedEstimationParamId,
        }))
      );
  }

  getProjectTypeLines() {
    return this.httpClient
      .get<ProjectTypeLine[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectTypeLine?expand=Skill, Product, ProjectType, ProductUnit&select=*,Skill, Product, ProjectType, ProductUnit`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectTypeLines) =>
          projectTypeLines.map(
            (p) => new ProjectTypeLine(this.camelcaseKeys(p))
          )
        )
      );
  }

  addProjectTypeLines(projectTypesLines: ProjectTypeLine[]) {
    const addedProjectTypesLines = projectTypesLines.map((p) =>
      this.capitalizeKeys(p, 'Skill', 'Product', 'ProductUnit', 'ProjectType')
    );
    return this.httpClient
      .post<ProjectTypeLine[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectTypeLine/records`
        ),
        addedProjectTypesLines,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectTypeLines) =>
          projectTypeLines.map(
            (p) => new ProjectTypeLine(this.camelcaseKeys(p))
          )
        )
      );
  }

  updateProjectTypeLines(projectTypeLines: ProjectTypeLine[]) {
    const updatedProjectTypeLines = projectTypeLines.map((p) =>
      this.capitalizeKeys(p, 'Skill', 'Product', 'ProductUnit', 'ProjectType')
    );
    return this.httpClient
      .patch<ProjectTypeLine[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectTypeLine/records`
        ),
        updatedProjectTypeLines,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectTypeLines) =>
          projectTypeLines.map(
            (p) => new ProjectTypeLine(this.camelcaseKeys(p))
          )
        )
      );
  }

  removeProjectTypeLines(id: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarProjectTypeLine/records`
      ),
      {
        body: id,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  getProjectEstimationParams() {
    return this.httpClient
      .get<ProjectEstimationParam[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectEstimationParam`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectEstimationParam) =>
          projectEstimationParam.map(
            (p) => new ProjectEstimationParam(this.camelcaseKeys(p))
          )
        )
      );
  }

  addProjectEstimationParams(
    projectEstimationParams: ProjectEstimationParam[]
  ) {
    const addedProjectEstimationParams = projectEstimationParams.map((p) =>
      this.capitalizeKeys(p)
    );
    return this.httpClient
      .post<ProjectEstimationParam[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectEstimationParam/records`
        ),
        addedProjectEstimationParams,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectEstimationParams) =>
          projectEstimationParams.map(
            (p) => new ProjectEstimationParam(this.camelcaseKeys(p))
          )
        )
      );
  }

  updateProjectEstimationParams(
    projectEstimationParams: ProjectEstimationParam[]
  ) {
    const updatedProjectEstimationParams = projectEstimationParams.map((p) =>
      this.capitalizeKeys(p)
    );
    return this.httpClient
      .patch<ProjectEstimationParam[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectEstimationParam/records`
        ),
        updatedProjectEstimationParams,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectEstimationParams) =>
          projectEstimationParams.map(
            (p) => new ProjectEstimationParam(this.camelcaseKeys(p))
          )
        )
      );
  }

  removeProjectEstimationParams(id: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarProjectEstimationParam/records`
      ),
      {
        body: id,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  getProjectEstimationParamFixedPercent() {
    return this.httpClient
      .get<ProjectEstimationParamFixedPercent[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectEstimationParamFixedPercent`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectEstimationParamFixedPercent) =>
          projectEstimationParamFixedPercent.map(
            (p) => new ProjectEstimationParamFixedPercent(this.camelcaseKeys(p))
          )
        )
      );
  }

  addProjectEstimationParamFixedPercent(
    projectEstimationParamFixedPercent: ProjectEstimationParamFixedPercent[]
  ) {
    const addedProjectEstimationParamFixedPercent =
      projectEstimationParamFixedPercent.map((p) =>
        this.capitalizeKeys(p, 'EstimationParam')
      );
    return this.httpClient
      .post<ProjectEstimationParamFixedPercent[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectEstimationParamFixedPercent/records`
        ),
        addedProjectEstimationParamFixedPercent,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectEstimationParamFixedPercent) =>
          projectEstimationParamFixedPercent.map(
            (p) => new ProjectEstimationParamFixedPercent(this.camelcaseKeys(p))
          )
        )
      );
  }

  updateProjectEstimationParamFixedPercent(
    projectEstimationParamFixedPercent: ProjectEstimationParamFixedPercent[]
  ) {
    const updatedProjectEstimationParamFixedPercent =
      projectEstimationParamFixedPercent.map((p) =>
        this.capitalizeKeys(p, 'EstimationParam')
      );
    return this.httpClient
      .patch<ProjectEstimationParamFixedPercent[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectEstimationParamFixedPercent/records`
        ),
        updatedProjectEstimationParamFixedPercent,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectEstimationParamFixedPercent) =>
          projectEstimationParamFixedPercent.map(
            (p) => new ProjectEstimationParamFixedPercent(this.camelcaseKeys(p))
          )
        )
      );
  }

  removeProjectEstimationParamFixedPercent(id: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarProjectEstimationParamFixedPercent/records`
      ),
      {
        body: id,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  getProjectMVPBlockTemplates() {
    return this.httpClient
      .get<ProjectMVPBlockTemplate[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectMVPBlockTemplate`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectMVPBlockTemplates) =>
          projectMVPBlockTemplates.map(
            (p) => new ProjectMVPBlockTemplate(this.camelcaseKeys(p))
          )
        )
      );
  }

  addProjectMVPBlockTemplates(
    projectMVPBlockTemplates: ProjectMVPBlockTemplate[]
  ) {
    const addedProjectMVPBlockTemplates = projectMVPBlockTemplates.map((p) =>
      this.capitalizeKeys(p)
    );
    return this.httpClient
      .post<ProjectMVPBlockTemplate[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectMVPBlockTemplate/records`
        ),
        addedProjectMVPBlockTemplates,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectMVPBlockTemplates) =>
          projectMVPBlockTemplates.map(
            (p) => new ProjectMVPBlockTemplate(this.camelcaseKeys(p))
          )
        )
      );
  }

  updateProjectMVPBlockTemplates(
    projectMVPBlockTemplates: ProjectMVPBlockTemplate[]
  ) {
    const updatedProjectMVPBlockTemplates = projectMVPBlockTemplates.map((p) =>
      this.capitalizeKeys(p)
    );
    return this.httpClient
      .patch<ProjectMVPBlockTemplate[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectMVPBlockTemplate/records`
        ),
        updatedProjectMVPBlockTemplates,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectMVPBlockTemplates) =>
          projectMVPBlockTemplates.map(
            (p) => new ProjectMVPBlockTemplate(this.camelcaseKeys(p))
          )
        )
      );
  }

  removeProjectMVPBlockTemplates(ids: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarProjectMVPBlockTemplate/records`
      ),
      {
        body: ids,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  getProjectMVPBlockTemplateLines() {
    return this.httpClient
      .get<ProjectMVPBlockTemplateLine[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectMVPBlockTemplateLine?expand=Skill,Product&select=*,Skill,Product`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectMVPBlockTemplateLines) =>
          projectMVPBlockTemplateLines.map(
            (p) => new ProjectMVPBlockTemplateLine(this.camelcaseKeys(p))
          )
        )
      );
  }

  addProjectMVPBlockTemplateLines(
    projectMVPBlockTemplateLines: ProjectMVPBlockTemplateLine[]
  ) {
    const addedProjectMVPBlockTemplateLines = projectMVPBlockTemplateLines.map(
      (p) =>
        this.capitalizeKeys(
          p,
          'Skill',
          'Product',
          'ProductUnit',
          'MVPBlockTemplate'
        )
    );
    return this.httpClient
      .post<ProjectMVPBlockTemplateLine[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectMVPBlockTemplateLine/records`
        ),
        addedProjectMVPBlockTemplateLines,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectMVPBlockTemplateLines) =>
          projectMVPBlockTemplateLines.map(
            (p) => new ProjectMVPBlockTemplateLine(this.camelcaseKeys(p))
          )
        )
      );
  }

  updateProjectMVPBlockTemplateLines(
    projectMVPBlockTemplateLines: ProjectMVPBlockTemplateLine[]
  ) {
    const updatedProjectMVPBlockTemplateLines =
      projectMVPBlockTemplateLines.map((p) =>
        this.capitalizeKeys(
          p,
          'Skill',
          'Product',
          'ProductUnit',
          'MVPBlockTemplate'
        )
      );
    return this.httpClient
      .patch<ProjectMVPBlockTemplateLine[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectMVPBlockTemplateLine/records`
        ),
        updatedProjectMVPBlockTemplateLines,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectMVPBlockTemplateLines) =>
          projectMVPBlockTemplateLines.map(
            (p) => new ProjectMVPBlockTemplateLine(this.camelcaseKeys(p))
          )
        )
      );
  }

  removeProjectMVPBlockTemplateLines(ids: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarProjectMVPBlockTemplateLine/records`
      ),
      {
        body: ids,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  getProjectMVPs(projectId: string) {
    return this.httpClient
      .get<ProjectMVP[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectMVP?expand=Project&select=*,Project&filter=Project/IshtarProjectId eq ${projectId}`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectMVPs) =>
          projectMVPs.map((p) => new ProjectMVP(this.camelcaseKeys(p)))
        )
      );
  }

  addProjectMVPs(projectMVPs: ProjectMVP[]) {
    const addedProjectMVPs = projectMVPs.map((p) =>
      this.capitalizeKeys(p, 'Project', 'Discounts')
    );
    return this.httpClient
      .post<ProjectMVP[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectMVP/records`
        ),
        addedProjectMVPs,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectMVPs) =>
          projectMVPs.map((p) => new ProjectMVP(this.camelcaseKeys(p)))
        )
      );
  }

  updateProjectMVPs(projectMVPs: ProjectMVP[]) {
    const updatedProjectMVPs = projectMVPs.map((p) =>
      this.capitalizeKeys(p, 'Project', 'Discounts')
    );
    return this.httpClient
      .patch<ProjectMVP[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectMVP/records`
        ),
        updatedProjectMVPs,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectMVPs) =>
          projectMVPs.map((p) => new ProjectMVP(this.camelcaseKeys(p)))
        )
      );
  }

  removeProjectMVPs(ids: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarProjectMVP/records`
      ),
      {
        body: ids,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  getProjectMVPLines(projectMVPIds: string[]) {
    let filterString = '&filter=';
    if (projectMVPIds.length > 0) {
      filterString =
        filterString +
        projectMVPIds
          .map((id) => `(MVP/IshtarProjectMVPId eq '${id}')`)
          .join(' or ');
    } else {
      filterString = '';
    }
    return this.httpClient
      .get<ProjectMVPLine[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectMVPLine?expand=Skill,Product, MVP&select=*,Skill,Product,MVP${filterString}`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectMVPLines) =>
          projectMVPLines.map((p) => new ProjectMVPLine(this.camelcaseKeys(p)))
        )
      );
  }

  addProjectMVPLines(projectMVPLines: ProjectMVPLine[]) {
    const addedProjectMVPLines = projectMVPLines.map((p) =>
      this.capitalizeKeys(p, 'Skill', 'Product', 'ProductUnit', 'MVP')
    );
    return this.httpClient
      .post<ProjectMVPLine[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectMVPLine/records`
        ),
        addedProjectMVPLines,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectMVPLines) =>
          projectMVPLines.map((p) => new ProjectMVPLine(this.camelcaseKeys(p)))
        )
      );
  }

  updateProjectMVPLines(projectMVPLines: ProjectMVPLine[]) {
    const updatedProjectMVPLines = projectMVPLines.map((p) =>
      this.capitalizeKeys(p, 'Skill', 'Product', 'ProductUnit', 'MVP')
    );
    return this.httpClient
      .patch<ProjectMVPLine[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectMVPLine/records`
        ),
        updatedProjectMVPLines,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectMVPLines) =>
          projectMVPLines.map((p) => new ProjectMVPLine(this.camelcaseKeys(p)))
        )
      );
  }

  removeProjectMVPLines(ids: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarProjectMVPLine/records`
      ),
      {
        body: ids,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  getProjectEstimations(projectId: string) {
    return this.httpClient
      .get<ProjectEstimation[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectEstimation?expand=Project,ApprovalStatus&select=*,Project,ApprovalStatus&filter=Project/IshtarProjectId eq ${projectId}`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectEstimations) =>
          projectEstimations.map(
            (p) => new ProjectEstimation(this.camelcaseKeys(p))
          )
        )
      );
  }

  addProjectEstimations(projectEstimations: ProjectEstimation[]) {
    const addedProjectEstimations = projectEstimations.map((p) =>
      this.capitalizeKeys(
        { ...p, fixedPercentages: p.fixedPercentages?.join('\n') },
        'Project',
        'ApprovalStatus'
      )
    );
    return this.httpClient
      .post<ProjectEstimation[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectEstimation/records`
        ),
        addedProjectEstimations,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectEstimations) =>
          projectEstimations.map(
            (p) => new ProjectEstimation(this.camelcaseKeys(p))
          )
        )
      );
  }

  updateProjectEstimations(projectEstimations: ProjectEstimation[]) {
    const updatedProjectEstimations = projectEstimations.map((p) =>
      this.capitalizeKeys(
        { ...p, fixedPercentages: p.fixedPercentages?.join('\n') },
        'Project',
        'ApprovalStatus'
      )
    );
    return this.httpClient
      .patch<ProjectEstimation[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarProjectEstimation/records`
        ),
        updatedProjectEstimations,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((projectEstimations) =>
          projectEstimations.map(
            (p) => new ProjectEstimation(this.camelcaseKeys(p))
          )
        )
      );
  }

  removeProjectEstimations(ids: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarProjectEstimation/records`
      ),
      {
        body: ids,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  getQuantityUnits() {
    return this.httpClient
      .get<QuantityUnit[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarQuantityUnit`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((quantityUnits) =>
          quantityUnits.map((q) => new QuantityUnit(this.camelcaseKeys(q)))
        )
      );
  }

  addQuantityUnits(quantityUnits: QuantityUnit[]) {
    const addedQuantityUnits = quantityUnits.map((q) => this.capitalizeKeys(q));
    return this.httpClient
      .post<QuantityUnit[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarQuantityUnit/records`
        ),
        addedQuantityUnits,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((quantityUnits) =>
          quantityUnits.map((q) => new QuantityUnit(this.camelcaseKeys(q)))
        )
      );
  }

  updateQuantityUnits(quantityUnits: QuantityUnit[]) {
    const updatedQuantityUnits = quantityUnits.map((q) =>
      this.capitalizeKeys(q)
    );
    return this.httpClient
      .patch<QuantityUnit[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarQuantityUnit/records`
        ),
        updatedQuantityUnits,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((quantityUnits) =>
          quantityUnits.map((q) => new QuantityUnit(this.camelcaseKeys(q)))
        )
      );
  }

  removeQuantityUnits(ids: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarQuantityUnit/records`
      ),
      {
        body: ids,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  getTranslations() {
    return this.httpClient.get<any>(
      this.createApiEndpointUrl(
        `ishtarapps/translations?lang=${varlicense$.value?.language}`
      )
    );
  }

  getUsers() {
    return this.httpClient
      .get<GroupUser[]>(
        this.createApiEndpointUrl(`users/${this.msal.tenantId}/Ishtar.Projects`)
      )
      .pipe(
        map((user) =>
          user.map(
            (u) =>
              new GroupUser({
                user: new AADUser(this.camelcaseKeys((u as any).User!)),
              })
          )
        )
      );
  }

  getGroups() {
    return this.httpClient
      .get<GroupUser[]>(
        this.createApiEndpointUrl(
          `groups/${this.msal.tenantId}/Ishtar.Projects`
        )
      )
      .pipe(
        map((group) => group.map((g) => new GroupUser(this.camelcaseKeys(g))))
      );
  }

  getStatus() {
    return this.httpClient
      .get<Status[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarStatus`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((status) => status.map((s) => new Status(this.camelcaseKeys(s))))
      );
  }

  getClients() {
    return this.httpClient
      .get<Client[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarClient`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((clients) => clients.map((c) => new Client(this.camelcaseKeys(c))))
      );
  }

  getDiscounts() {
    return this.httpClient
      .get<Discount[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarDiscount`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((discounts) =>
          discounts.map((d) => new Discount(this.camelcaseKeys(d)))
        )
      );
  }

  addDiscounts(discounts: Discount[]) {
    const addedDiscounts = discounts.map((p) => this.capitalizeKeys(p));
    return this.httpClient
      .post<Discount[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarDiscount/records`
        ),
        addedDiscounts,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((discounts) =>
          discounts.map((d) => new Discount(this.camelcaseKeys(d)))
        )
      );
  }

  updateDiscounts(discounts: Discount[]) {
    const updatedDiscounts = discounts.map((p) => this.capitalizeKeys(p));
    return this.httpClient
      .patch<Discount[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarDiscount/records`
        ),
        updatedDiscounts,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((discounts) =>
          discounts.map((d) => new Discount(this.camelcaseKeys(d)))
        )
      );
  }

  removeDiscounts(ids: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarDiscount/records`
      ),
      {
        body: ids,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  getConfig() {
    return this.httpClient
      .get<Config>(
        this.createApiEndpointUrl(
          `subscription/${this.msal.tenantId}/Ishtar.Projects/config`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(map((config) => new Config(this.camelcaseKeys(config))));
  }

  updateConfig(config: Config) {
    const updatedConfig = this.capitalizeKeys(config);

    return this.httpClient
      .post<Config>(
        this.createApiEndpointUrl(
          `subscription/${this.msal.tenantId}/Ishtar.Projects/config`
        ),
        updatedConfig,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((c) => {
          const cc = new Config(this.camelcaseKeys(c));
          varconfig$.next(cc);
          return cc;
        })
      );
  }

  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),

        {}
      );
    else return obj;
  }
  capitalizeKeys(obj: any, ...ignoredProperties: string[]): any {
    const ignoredPropertiesLower = ignoredProperties.map((p) =>
      p.toLowerCase()
    );

    if (Array.isArray(obj))
      return [...obj.map((o) => this.capitalizeKeys(o, ...ignoredProperties))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toUpperCase() + e[0].slice(1)]:
            ignoredPropertiesLower.includes(e[0].toLowerCase())
              ? e[1]
              : this.capitalizeKeys(e[1], ...ignoredProperties),
        }),

        {}
      );
    else return obj;
  }
}
