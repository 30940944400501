export class ProjectMVPLine {
  ishtarProjectMVPLineId?: string;
  title!: string;
  description?: string;
  mVP!: { id: string; name?: string };
  amount?: number;
  unitPrice?: number;
  rowNr?: number;
  skillUnit?: string;
  productUnit?: { id: string; name?: string };
  skill?: { id: string; name?: string };
  product?: { id: string; name?: string };
  id?: string;
  name?: string;

  constructor(obj: Partial<ProjectMVPLine>) {
    Object.assign(this, obj);
  }
}
