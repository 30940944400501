export class ProjectEstimationParamFixedPercent {
  ishtarProjectEstimationParamFixedPercentId?: string;
  title!: string;
  isLineSpecific?: boolean;
  amount?: number;
  estimationParam?: { id: string; name?: string };

  constructor(obj: Partial<ProjectEstimationParamFixedPercent>) {
    Object.assign(this, obj);
  }
}
