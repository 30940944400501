export class ProjectTypeLine {
  ishtarProjectTypeLineId?: string;
  title!: string;
  product?: { id: string; name?: string };
  skill?: { id: string; name?: string };
  projectType?: { id: string; name?: string };
  productUnit?: { id: string; name?: string };
  skillUnit?: string;
  amount?: number;

  constructor(obj: Partial<ProjectTypeLine>) {
    Object.assign(this, obj);
  }
}
