import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  filter,
  first,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { GroupUser } from '../models/user/groupuser';
import { UserLicenseInfo } from '../models/user/userlicenseinfo';
import { IshtarProjectService } from './project.service';
import { Status } from '../models/project/status';
import { Client } from '../models/project/client';
import { QuantityUnit } from '../models/project/quantityUnit';
import { Config } from '../models/project/config';
import { Router } from '@angular/router';
import {
  Ishtar365CommunicationService,
  LoaderService,
  TranslationService,
} from 'processdelight-angular-components';
import { AADUser } from '../models/user/aadUser';
import { Group } from '../models/user/group';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { getSkillsResolved } from '../store/skill/skill.actions';
import { Skill } from '../models/project/skill';
import {
  getProductsResolved,
  getQuantityUnitsResolved,
} from '../store/product/product.actions';
import { Product } from '../models/project/product';
import { getDefaultRatesResolved } from '../store/rate/rate.actions';
import { Rate } from '../models/project/rate';
import { getProjectTypesResolved } from '../store/project-type/project-type.actions';
import { ProjectType } from '../models/project/projectType';
import { getProjectTypeLinesResolved } from '../store/project-type-line/project-type-line.actions';
import { ProjectTypeLine } from '../models/project/projectTypeLine';
import { getProjectMVPBlockTemplateLinesResolved } from '../store/projectMVPBlockTemplateLine/projectMVPBlockTemplateLine.actions';
import { getProjectMVPBlockTemplatesResolved } from '../store/projectMVPBlockTemplate/projectMVPBlockTemplate.actions';
import { ProjectMVPBlockTemplate } from '../models/project/projectMVPBlockTemplate';
import { ProjectMVPBlockTemplateLine } from '../models/project/projectMVPBlockTemplateLine';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getProjectEstimationParamsResolved } from '../store/projectEstimationParam/projectEstimationParam.actions';
import { ProjectEstimationParam } from '../models/project/projectEstimationParam';
import { getProjectEstimationParamFixedPercentResolved } from '../store/projectEstimationParamFixedPercent/projectEstimationParamFixedPercent.actions';
import { ProjectEstimationParamFixedPercent } from '../models/project/projectEstimationParamFixedPercent';
import {
  getProjectTemplatesResolved,
  getProjectsResolved,
} from '../store/project/project.actions';
import { Project } from '../models/project/project';
import { getDiscountsResolved } from '../store/projectMVP/projectMVP.actions';
import { Discount } from '../models/project/discount';

export const varlicense$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);

export const varusers$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);

export const vargroups$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);

export const varstatus$ = new BehaviorSubject<Status[] | undefined>(undefined);

export const varclients$ = new BehaviorSubject<Client[] | undefined>(undefined);

export const vartranslations$ = new BehaviorSubject<any>({});

export const varconfig$ = new BehaviorSubject<Config | undefined>(undefined);

@Injectable({
  providedIn: 'root',
})
export class StartUpService {
  constructor(
    private ishtarProjectService: IshtarProjectService,
    private _store: Store<AppState>,
    private router: Router,
    private loaderService: LoaderService,
    private translationService: TranslationService,
    private _snackBar: MatSnackBar,
    private ishtar365communicationService: Ishtar365CommunicationService
  ) {}

  getLicense() {
    return this.loaderService.startLoading$(
      'Retrieving license information',
      () =>
        this.ishtarProjectService.getLicense().pipe(
          tap((data) => varlicense$.next(data)),
          catchError((error) => {
            this.router.navigate(['/401']);
            throw error;
          })
        )
    );
  }

  boot() {
    return this.getLicense().pipe(
      filter((license) => !!license),
      first(),
      switchMap((license) => {
        if (
          !license?.licenses.some((l) => l.productName == 'Ishtar.Projects')
        ) {
          this.router.navigate(['/401']);
          throw new Error('No license for Ishtar.Projects');
        }
        return this.loaderService.startLoading$('Starting application...', () =>
          this.ishtarProjectService
            .getStartUpData(
              license.microsoftId,
              license.email,
              license.language!
            )
            .pipe(
              tap((data) => {
                try {
                  this.ishtar365communicationService.init();
                  this.ishtar365communicationService.registerRedirectAction('Project', (id?: string) => {
                    this.router.navigate(['projects'], {
                      queryParams: { projectId: id },
                    });
                  })
                  this.ishtar365communicationService.registerRedirectActions({
                    Project: (id, data) => {
                      this.router.navigate(['projects'], {
                        queryParams: { projectId: id },
                      });
                    },
                  });
                } catch {
                  // not running in Ishtar365
                }
                varlicense$.next(license);
                vartranslations$.next(
                  data.translations ? data.translations : {}
                );
                this.translationService.update(
                  data.translations ? data.translations : {}
                );
                varusers$.next(
                  data.users
                    ? data.users.map(
                        (u: GroupUser) =>
                          new GroupUser({
                            user: new AADUser(
                              this.camelcaseKeys((u as any).User!)
                            ),
                          })
                      )
                    : []
                );
                vargroups$.next(
                  data.groups
                    ? data.groups.map(
                        (u: GroupUser) =>
                          new GroupUser({
                            group: new Group(
                              this.camelcaseKeys((u as any).Group!)
                            ),
                          })
                      )
                    : []
                );
                varstatus$.next(
                  data.status
                    ? data.status.map(
                        (a: Status) => new Status(this.camelcaseKeys(a))
                      )
                    : []
                );
                varclients$.next(
                  data.clients
                    ? data.clients.map(
                        (a: Client) => new Client(this.camelcaseKeys(a))
                      )
                    : []
                );
                varconfig$.next(
                  data.projectsConfig
                    ? this.camelcaseKeys(data.projectsConfig)
                    : {}
                );
                this._store.dispatch(
                  getSkillsResolved({
                    skills: data.skills
                      ? data.skills.map(
                          (a: Skill) => new Skill(this.camelcaseKeys(a))
                        )
                      : [],
                  })
                );
                this._store.dispatch(
                  getProjectsResolved({
                    projects: data.projects
                      ? data.projects.map(
                          (a: Project) => new Project(this.camelcaseKeys(a))
                        )
                      : [],
                  })
                );

                this._store.dispatch(
                  getProductsResolved({
                    products: data.products
                      ? data.products.map(
                          (a: Product) => new Product(this.camelcaseKeys(a))
                        )
                      : [],
                  })
                );
                this._store.dispatch(
                  getDefaultRatesResolved({
                    rates: data.rates
                      ? data.rates.map(
                          (a: Rate) => new Rate(this.camelcaseKeys(a))
                        )
                      : [],
                  })
                );
                this._store.dispatch(
                  getProjectTypesResolved({
                    projectTypes: data.projectTypes
                      ? data.projectTypes.map(
                          (a: ProjectType) =>
                            new ProjectType(this.camelcaseKeys(a))
                        )
                      : [],
                  })
                );
                this._store.dispatch(
                  getProjectTypeLinesResolved({
                    projectTypeLines: data.projectTypeLines
                      ? data.projectTypeLines.map(
                          (a: ProjectTypeLine) =>
                            new ProjectTypeLine(this.camelcaseKeys(a))
                        )
                      : [],
                  })
                );
                this._store.dispatch(
                  getProjectMVPBlockTemplatesResolved({
                    projectMVPBlockTemplates: data.projectMVPBlockTemplates
                      ? data.projectMVPBlockTemplates.map(
                          (a: ProjectMVPBlockTemplate) =>
                            new ProjectMVPBlockTemplate(this.camelcaseKeys(a))
                        )
                      : [],
                  })
                );
                this._store.dispatch(
                  getProjectMVPBlockTemplateLinesResolved({
                    projectMVPBlockTemplateLines:
                      data.projectMVPBlockTemplateLines
                        ? data.projectMVPBlockTemplateLines.map(
                            (a: ProjectMVPBlockTemplateLine) =>
                              new ProjectMVPBlockTemplateLine(
                                this.camelcaseKeys(a)
                              )
                          )
                        : [],
                  })
                );
                this._store.dispatch(
                  getProjectEstimationParamsResolved({
                    projectEstimationParams: data.projectEstimationParams
                      ? data.projectEstimationParams.map(
                          (a: ProjectEstimationParam) =>
                            new ProjectEstimationParam(this.camelcaseKeys(a))
                        )
                      : [],
                  })
                );
                this._store.dispatch(
                  getProjectEstimationParamFixedPercentResolved({
                    projectEstimationParamFixedPercent:
                      data.projectEstimationParamFixedPercents
                        ? data.projectEstimationParamFixedPercents.map(
                            (a: ProjectEstimationParamFixedPercent) =>
                              new ProjectEstimationParamFixedPercent(
                                this.camelcaseKeys(a)
                              )
                          )
                        : [],
                  })
                );
                this._store.dispatch(
                  getQuantityUnitsResolved({
                    quantityUnits: data.quantityUnits
                      ? data.quantityUnits.map(
                          (a: QuantityUnit) =>
                            new QuantityUnit(this.camelcaseKeys(a))
                        )
                      : [],
                  })
                );
                this._store.dispatch(
                  getProjectTemplatesResolved({
                    projects: data.projectTemplates
                      ? data.projectTemplates.map(
                          (a: Project) => new Project(this.camelcaseKeys(a))
                        )
                      : [],
                  })
                );
                this._store.dispatch(
                  getDiscountsResolved({
                    discounts: data.discounts
                      ? data.discounts.map(
                          (a: Discount) => new Discount(this.camelcaseKeys(a))
                        )
                      : [],
                  })
                );
              }),
              catchError((error) => {
                this._snackBar
                  .open(
                    'Error while starting application. Please refresh the page.',
                    'X',
                    {
                      panelClass: 'app-notification-error',
                    }
                  )
                  ._dismissAfter(10000);
                return of(true);
              })
            )
        );
      })
    );
  }
  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),

        {}
      );
    else return obj;
  }
}
