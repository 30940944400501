export class Product {
  ishtarProjectsProductId?: string;
  title!: string;
  eAN?: string;
  color!: string;
  description?: string;
  definedUnits?: { id: string; name?: string }[];
  isDeleted?: boolean;
  id?: string;
  name?: string;

  constructor(obj: Partial<Product>) {
    Object.assign(this, obj);
  }
}
