import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import { ProjectEstimationParamFixedPercent } from '../../models/project/projectEstimationParamFixedPercent';
import { estimationParamDeletedActionResolved } from './projectEstimationParamFixedPercent.actions';
import {
  getProjectEstimationParamFixedPercentResolved,
  removeProjectEstimationParamFixedPercentResolved,
  updateProjectEstimationParamFixedPercentResolved,
  addProjectEstimationParamFixedPercentResolved,
} from './projectEstimationParamFixedPercent.actions';

export const featureSlice = 'projectEstimationParamFixedPercent';

export interface State {
  projectEstimationParamFixedPercentages?: ProjectEstimationParamFixedPercent[];
  projectEstimationParamFixedPercent?: ProjectEstimationParamFixedPercent;
  ishtarProjectEstimationParamFixedPercentIds?: string[];
}
const defaultState: State = {
  projectEstimationParamFixedPercentages: undefined,
  projectEstimationParamFixedPercent: undefined,
  ishtarProjectEstimationParamFixedPercentIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return projectEstimationParamFixedPercentReducer(state, action);
}

export const initialState: State = defaultState;

export const projectEstimationParamFixedPercentReducer = createReducer(
  initialState,
  on(
    getProjectEstimationParamFixedPercentResolved,
    (state, { projectEstimationParamFixedPercent }) => ({
      ...state,
      projectEstimationParamFixedPercentages: [
        ...projectEstimationParamFixedPercent,
      ],
    })
  ),
  on(
    removeProjectEstimationParamFixedPercentResolved,
    (state, { ishtarProjectEstimationParamFixedPercentIds }) => ({
      ...state,
      projectEstimationParamFixedPercentages:
        state.projectEstimationParamFixedPercentages?.filter(
          (p) =>
            !ishtarProjectEstimationParamFixedPercentIds.includes(
              p.ishtarProjectEstimationParamFixedPercentId!
            )
        ),
    })
  ),
  on(
    updateProjectEstimationParamFixedPercentResolved,
    (state, { updatedProjectEstimationParamFixedPercent }) => ({
      ...state,
      projectEstimationParamFixedPercentages:
        state.projectEstimationParamFixedPercentages?.map(
          (p) =>
            updatedProjectEstimationParamFixedPercent.find(
              (u) =>
                p.ishtarProjectEstimationParamFixedPercentId ===
                u.ishtarProjectEstimationParamFixedPercentId
            ) ?? p
        ),
    })
  ),
  on(
    addProjectEstimationParamFixedPercentResolved,
    (state, { addedProjectEstimationParamFixedPercent }) => ({
      ...state,
      projectEstimationParamFixedPercentages:
        addedProjectEstimationParamFixedPercent.concat([
          ...(state.projectEstimationParamFixedPercentages ?? []),
        ]),
    })
  ),
  on(estimationParamDeletedActionResolved, (state, { deletedEstimationParamId }) => ({
    ...state,
    projectEstimationParamFixedPercentages: state.projectEstimationParamFixedPercentages?.filter(
      (p) => p.estimationParam?.id !== deletedEstimationParamId
    ),
  }))
);

export const projectEstimationParamFixedPercentState = (state: AppState) =>
  state.coreFeature.projectEstimationParamFixedPercent;
