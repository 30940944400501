export class ProjectMVP {
  ishtarProjectMVPId?: string;
  title!: string;
  scope!: number;
  discounts?: { id: string; name?: string }[];
  project!: { id: string; name?: string };
  id?: string;
  name?: string;

  constructor(obj: Partial<ProjectMVP>) {
    Object.assign(this, obj);
  }
}
