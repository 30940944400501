export class ProjectType {
  ishtarProjectTypeId?: string;
  title!: string;
  hasEstimation?: boolean;
  estimationParam?: { id: string; name?: string };
  id?: string;
  name?: string;

  constructor(obj: Partial<ProjectType>) {
    Object.assign(this, obj);
  }
}
