export class ProjectMVPBlockTemplateLine {
  ishtarProjectMVPBlockTemplateLineId?: string;
  title!: string;
  description?: string;
  amount?: number;
  rowNr?: number;
  required?: boolean;
  skill?: { id: string; name?: string };
  product?: { id: string; name?: string };
  mVPBlockTemplate!: { id: string; name?: string };
  productUnit?: { id: string; name?: string };
  skillUnit?: string;

  constructor(obj: Partial<ProjectMVPBlockTemplateLine>) {
    Object.assign(this, obj);
  }
}
