import { Skill } from './skill';
import { Product } from 'src/app/core/models/project/product';

export class Rate {
  ishtarRateId?: string;
  title!: string;
  skill?: { id: string; name?: string };
  product?: { id: string; name?: string };
  project?: { id: string; name?: string };
  hourlyRate?: number;
  fixedPercentage?: number;
  quantityUnit?: { id: string; name?: string };
  id?: string;
  name?: string;

  constructor(obj: Partial<Rate>) {
    Object.assign(this, obj);
  }
}
