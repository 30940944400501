import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarProjectService } from '../../services/project.service';
import {
  addProjectMVPBlockTemplateLines,
  addProjectMVPBlockTemplateLinesResolved,
  getProjectMVPBlockTemplateLines,
  getProjectMVPBlockTemplateLinesResolved,
  removeProjectMVPBlockTemplateLines,
  removeProjectMVPBlockTemplateLinesResolved,
  updateProjectMVPBlockTemplateLines,
  updateProjectMVPBlockTemplateLinesResolved,
} from './projectMVPBlockTemplateLine.actions';

@Injectable({ providedIn: 'root' })
export class ProjectMVPBlockTemplateLineEffects {
  constructor(
    private actions$: Actions,
    private ishtarProjectService: IshtarProjectService
  ) {}

  getProjectMVPBlockTemplateLines = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectMVPBlockTemplateLines),
      switchMap(({ callback }) =>
        this.ishtarProjectService.getProjectMVPBlockTemplateLines().pipe(
          switchMap((projectMVPBlockTemplateLines) =>
            of(
              getProjectMVPBlockTemplateLinesResolved({
                projectMVPBlockTemplateLines,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addProjectMVPBlockTemplateLines = createEffect(() =>
    this.actions$.pipe(
      ofType(addProjectMVPBlockTemplateLines),
      switchMap(({ projectMVPBlockTemplateLines, callback, error }) =>
        this.ishtarProjectService
          .addProjectMVPBlockTemplateLines(projectMVPBlockTemplateLines)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((addedProjectMVPBlockTemplateLines) =>
              of(
                addProjectMVPBlockTemplateLinesResolved({
                  addedProjectMVPBlockTemplateLines,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  updateProjectMVPBlockTemplateLines = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProjectMVPBlockTemplateLines),
      switchMap(({ projectMVPBlockTemplateLines, callback, error }) =>
        this.ishtarProjectService
          .updateProjectMVPBlockTemplateLines(projectMVPBlockTemplateLines)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((updatedProjectMVPBlockTemplateLines) =>
              of(
                updateProjectMVPBlockTemplateLinesResolved({
                  updatedProjectMVPBlockTemplateLines,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  removeProjectMVPBlockTemplateLines = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProjectMVPBlockTemplateLines),
      switchMap(({ ids, callback, error }) =>
        this.ishtarProjectService.removeProjectMVPBlockTemplateLines(ids).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((ishtarProjectMVPBlockTemplateLineIds) =>
            of(
              removeProjectMVPBlockTemplateLinesResolved({
                ishtarProjectMVPBlockTemplateLineIds,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
