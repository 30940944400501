import { createAction, props } from '@ngrx/store';
import { ProjectEstimation } from '../../models/project/projectEstimation';
import { ActionBase } from '../action-base.interface';

export const getProjectEstimations = createAction(
  '[ProjectEstimation] Get projectEstimations',
  props<{
    projectId: string;
    callback?: (projectEstimations: ProjectEstimation[]) => void;
  }>()
);

export const getProjectEstimationsResolved = createAction(
  '[ProjectEstimation] Get projectEstimations resolved',
  props<{
    projectEstimations: ProjectEstimation[];
  }>()
);

export const removeProjectEstimations = createAction(
  '[ProjectEstimation] Remove projectEstimations',
  props<{ ids: string[] } & ActionBase<string[]>>()
);

export const removeProjectEstimationsResolved = createAction(
  '[ProjectEstimation] Remove projectEstimations resolved',
  props<{ ishtarProjectEstimationIds: string[] }>()
);

export const updateProjectEstimations = createAction(
  '[ProjectEstimation] Update projectEstimations',
  props<
    { projectEstimations: ProjectEstimation[] } & ActionBase<
      ProjectEstimation[]
    >
  >()
);

export const updateProjectEstimationsResolved = createAction(
  '[ProjectEstimation] Update projectEstimations resolved',
  props<{ updatedProjectEstimations: ProjectEstimation[] }>()
);

export const addProjectEstimations = createAction(
  '[ProjectEstimation] Add projectEstimations',
  props<
    {
      projectEstimations: ProjectEstimation[];
    } & ActionBase<ProjectEstimation[]>
  >()
);

export const addProjectEstimationsResolved = createAction(
  '[ProjectEstimation] Add projectEstimations resolved',
  props<{ addedProjectEstimations: ProjectEstimation[] }>()
);

export const onProjectDeletedEstimationActionResolved = createAction(
  '[ProjectEstimation] Project deleted estimation action resolved',
  props<{
    deletedProjectId: string;
  }>()
);
