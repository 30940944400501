import { AADUser } from '../user/aadUser';

export class ProjectMVPBlockTemplate {
  ishtarProjectMVPBlockTemplateId?: string;
  title!: string;
  id?: string;
  name?: string;

  constructor(obj: Partial<ProjectMVPBlockTemplate>) {
    Object.assign(this, obj);
  }
}
