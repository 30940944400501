import { Action, createReducer, on } from '@ngrx/store';
import { Project } from '../../models/project/project';
import { AppState } from '../../../app.reducer';
import {
  getProjectsResolved,
  removeProjectResolved,
  updateProjectResolved,
  addProjectResolved,
  getProjectResolved,
  getProjectTemplatesResolved,
  addProjectTemplateResolved,
} from './project.actions';

export const featureSlice = 'project';

export interface State {
  projects: Project[];
  templateProjects: Project[];
  project?: Project;
  ishtarProjectId?: string;
  pagingCookie: string;
  totalRecordCount: number;
}
const defaultState: State = {
  projects: [],
  templateProjects: [],
  project: undefined,
  ishtarProjectId: undefined,
  pagingCookie: '',
  totalRecordCount: 0,
};

export function Reducer(state: State | undefined, action: Action) {
  return projectReducer(state, action);
}

export const initialState: State = defaultState;

export const projectReducer = createReducer(
  initialState,
  on(getProjectsResolved, (state, { projects }) => ({
    ...state,
    projects: [...projects],
  })),
  on(getProjectResolved, (state, { project }) => ({
    ...state,
    project: project,
  })),
  on(getProjectTemplatesResolved, (state, { projects }) => ({
    ...state,
    templateProjects: [...projects],
  })),
  on(removeProjectResolved, (state, { ishtarProjectIds }) => ({
    ...state,
    projects: state.projects.filter(
      (p) => !ishtarProjectIds.includes(p.ishtarProjectId!)
    ),
    templateProjects: state.templateProjects.filter(
      (p) => !ishtarProjectIds.includes(p.ishtarProjectId!)
    ),
  })),
  on(updateProjectResolved, (state, { project }) => ({
    ...state,
    projects: state.projects.map((p) =>
      p.ishtarProjectId == project.ishtarProjectId ? project : p
    ),
    templateProjects: state.templateProjects.map((p) =>
      p.ishtarProjectId == project.ishtarProjectId ? project : p
    ),
  })),
  on(addProjectResolved, (state, { addedProject }) => ({
    ...state,
    projects: [addedProject].concat([...state.projects]),
  })),
  on(addProjectTemplateResolved, (state, { project }) => ({
    ...state,
    templateProjects: [project].concat([...state.templateProjects]),
  }))
);

export const projectState = (state: AppState) => state.coreFeature.project;
