export class Skill {
  ishtarSkillId?: string;
  title!: string;
  code!: string;
  color!: string;
  billable!: boolean;
  isDeleted?: boolean;
  id?: string;
  name?: string;

  constructor(obj: Partial<Skill>) {
    Object.assign(this, obj);
  }
}
