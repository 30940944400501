import { AADUser } from '../user/aadUser';
import { GroupUser } from '../user/groupuser';

export class ProjectEstimation {
  ishtarProjectEstimationId?: string;
  title!: string;
  amount?: number;
  project!: { id: string; name?: string };
  createdBy?: { id: string; name?: string };
  approvalStatus?: { id: string; name?: string };
  approver?: GroupUser;
  fixedPercentages?: string[];

  constructor(obj: Partial<ProjectEstimation>) {
    Object.assign(this, obj);
    if (obj.fixedPercentages && typeof obj.fixedPercentages == 'string')
      this.fixedPercentages = (obj.fixedPercentages as string).split('\n');
  }
}
