export class ProjectEstimationParam {
  ishtarProjectEstimationParamId?: string;
  title!: string;
  useStoryPoints?: boolean;
  storyPointAmount?: number;
  id?: string;
  name?: string;

  constructor(obj: Partial<ProjectEstimationParam>) {
    Object.assign(this, obj);
  }
}
