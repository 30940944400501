import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import { ProjectMVPBlockTemplate } from '../../models/project/projectMVPBlockTemplate';
import {
  getProjectMVPBlockTemplatesResolved,
  removeProjectMVPBlockTemplatesResolved,
  updateProjectMVPBlockTemplatesResolved,
  addProjectMVPBlockTemplatesResolved,
} from './projectMVPBlockTemplate.actions';

export const featureSlice = 'projectMVPBlockTemplate';

export interface State {
  projectMVPBlockTemplates?: ProjectMVPBlockTemplate[];
  projectMVPBlockTemplate?: ProjectMVPBlockTemplate;
  ishtarProjectMVPBlockTemplateids?: string[];
}
const defaultState: State = {
  projectMVPBlockTemplates: undefined,
  projectMVPBlockTemplate: undefined,
  ishtarProjectMVPBlockTemplateids: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return projectMVPBlockTemplateReducer(state, action);
}

export const initialState: State = defaultState;

export const projectMVPBlockTemplateReducer = createReducer(
  initialState,
  on(
    getProjectMVPBlockTemplatesResolved,
    (state, { projectMVPBlockTemplates }) => ({
      ...state,
      projectMVPBlockTemplates: [...projectMVPBlockTemplates],
    })
  ),
  on(
    removeProjectMVPBlockTemplatesResolved,
    (state, { ishtarProjectMVPBlockTemplateIds }) => ({
      ...state,
      projectMVPBlockTemplates: state.projectMVPBlockTemplates?.filter(
        (p) =>
          !ishtarProjectMVPBlockTemplateIds.includes(
            p.ishtarProjectMVPBlockTemplateId!
          )
      ),
    })
  ),
  on(
    updateProjectMVPBlockTemplatesResolved,
    (state, { updatedProjectMVPBlockTemplates }) => ({
      ...state,
      projectMVPBlockTemplates: state.projectMVPBlockTemplates?.map(
        (p) =>
          updatedProjectMVPBlockTemplates.find(
            (u) =>
              p.ishtarProjectMVPBlockTemplateId ===
              u.ishtarProjectMVPBlockTemplateId
          ) ?? p
      ),
    })
  ),
  on(
    addProjectMVPBlockTemplatesResolved,
    (state, { addedProjectMVPBlockTemplates }) => ({
      ...state,
      projectMVPBlockTemplates: addedProjectMVPBlockTemplates.concat([
        ...(state.projectMVPBlockTemplates ?? []),
      ]),
    })
  )
);

export const projectMVPBlockTemplateState = (state: AppState) =>
  state.coreFeature.projectMVPBlockTemplate;
