import { createAction, props } from '@ngrx/store';
import { Product } from '../../models/project/product';
import { QuantityUnit } from '../../models/project/quantityUnit';
import { ActionBase } from '../action-base.interface';

export const getProducts = createAction(
  '[Products] Get products',
  props<ActionBase<Product[]>>()
);

export const getProductsResolved = createAction(
  '[Products] Get products resolved',
  props<{
    products: Product[];
  }>()
);

export const removeProduct = createAction(
  '[Products] Remove products',
  props<
    { productId: string } & ActionBase<{
      deletedProjectTypeLineIds: string[] | undefined;
      deletedProjectMVPBlockTemplateLineIds: string[] | undefined;
      deletedProductId: string;
    }>
  >()
);

export const removeProductResolved = createAction(
  '[Products] Remove products resolved',
  props<{ ishtarProductId: string }>()
);

export const updateProducts = createAction(
  '[Products] Update products',
  props<
    {
      products: Product[];
    } & ActionBase<Product[]>
  >()
);

export const updateProductsResolved = createAction(
  '[Products] Update products resolved',
  props<{ updatedProducts: Product[] }>()
);

export const addProducts = createAction(
  '[Products] Add products',
  props<
    {
      products: Product[];
    } & ActionBase<Product[]>
  >()
);

export const addProductsResolved = createAction(
  '[Products] Add products resolved',
  props<{ addedProducts: Product[] }>()
);

export const getQuantityUnits = createAction(
  '[QuantityUnit] Get quantity units',
  props<ActionBase<QuantityUnit[]>>()
);

export const getQuantityUnitsResolved = createAction(
  '[QuantityUnit] Get quantity units resolved',
  props<{
    quantityUnits: QuantityUnit[];
  }>()
);

export const removeQuantityUnit = createAction(
  '[QuantityUnit] Remove quantity unit',
  props<
    {
      ishtarQuantityUnitIds: string[];
    } & ActionBase<string[]>
  >()
);

export const removeQuantityUnitResolved = createAction(
  '[QuantityUnit] Remove quantity unit resolved',
  props<{ ishtarQuantityUnitIds: string[] }>()
);

export const updateQuantityUnits = createAction(
  '[QuantityUnit] Update quantity units',
  props<
    {
      quantityUnits: QuantityUnit[];
    } & ActionBase<QuantityUnit[]>
  >()
);

export const updateQuantityUnitsResolved = createAction(
  '[QuantityUnit] Update quantity units resolved',
  props<{ updatedQuantityUnits: QuantityUnit[] }>()
);

export const addQuantityUnits = createAction(
  '[QuantityUnit] Add quantity units',
  props<
    {
      quantityUnits: QuantityUnit[];
    } & ActionBase<QuantityUnit[]>
  >()
);

export const addQuantityUnitsResolved = createAction(
  '[QuantityUnit] Add quantity units resolved',
  props<{ addedQuantityUnits: QuantityUnit[] }>()
);
