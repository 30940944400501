import { createAction, props } from '@ngrx/store';
import { Skill } from '../../models/project/skill';
import { ActionBase } from '../action-base.interface';

export const getSkills = createAction(
  '[Skill] Get skills',
  props<{
    callback?: () => void;
  }>()
);

export const getSkillsResolved = createAction(
  '[Skill] Get skills resolved',
  props<{
    skills: Skill[];
  }>()
);

export const removeSkill = createAction(
  '[Skill] Remove skill',
  props<
    { skillId: string } & ActionBase<{
      deletedProjectTypeLineIds: string[] | undefined;
      deletedProjectMVPBlockTemplateLineIds: string[] | undefined;
      deletedSkillId: string;
    }>
  >()
);

export const removeSkillResolved = createAction(
  '[Skill] Remove skill resolved',
  props<{ ishtarSkillId: string }>()
);

export const updateSkills = createAction(
  '[Skills] Update skills',
  props<{ skills: Skill[] } & ActionBase<Skill[]>>()
);

export const updateSkillsResolved = createAction(
  '[Skills] Update skills resolved',
  props<{ updatedSkills: Skill[] }>()
);

export const addSkills = createAction(
  '[Skills] Add skills',
  props<{ skills: Skill[] } & ActionBase<Skill[]>>()
);

export const addSkillsResolved = createAction(
  '[Skills] Add skills resolved',
  props<{ addedSkills: Skill[] }>()
);
