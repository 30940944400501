import { AADUser } from './aadUser';

export class Group {
  displayName!: string;
  groupId!: string;
  owners!: AADUser[];
  members?: AADUser[];

  constructor(obj: Partial<Group>) {
    Object.assign(this, obj);
  }
}
