import { createAction, props } from '@ngrx/store';
import { ProjectMVPBlockTemplateLine } from '../../models/project/projectMVPBlockTemplateLine';
import { ActionBase } from '../action-base.interface';

export const getProjectMVPBlockTemplateLines = createAction(
  '[ProjectMVPBlockTemplateLine] Get projectMVPBlockTemplateLines',
  props<{
    callback?: () => void;
  }>()
);

export const getProjectMVPBlockTemplateLinesResolved = createAction(
  '[ProjectMVPBlockTemplateLines] Get projectMVPBlockTemplateLines resolved',
  props<{
    projectMVPBlockTemplateLines: ProjectMVPBlockTemplateLine[];
  }>()
);

export const removeProjectMVPBlockTemplateLines = createAction(
  '[ProjectMVPBlockTemplateLines] Remove projectMVPBlockTemplateLines',
  props<{ ids: string[] } & ActionBase<string[]>>()
);

export const removeProjectMVPBlockTemplateLinesResolved = createAction(
  '[ProjectMVPBlockTemplateLines] Remove projectMVPBlockTemplateLines resolved',
  props<{ ishtarProjectMVPBlockTemplateLineIds: string[] }>()
);

export const updateProjectMVPBlockTemplateLines = createAction(
  '[ProjectMVPBlockTemplateLines] Update projectMVPBlockTemplateLines',
  props<
    {
      projectMVPBlockTemplateLines: ProjectMVPBlockTemplateLine[];
    } & ActionBase<ProjectMVPBlockTemplateLine[]>
  >()
);

export const updateProjectMVPBlockTemplateLinesResolved = createAction(
  '[ProjectMVPBlockTemplateLines] Update projectMVPBlockTemplateLine resolved',
  props<{
    updatedProjectMVPBlockTemplateLines: ProjectMVPBlockTemplateLine[];
  }>()
);

export const addProjectMVPBlockTemplateLines = createAction(
  '[ProjectMVPBlockTemplateLines] Add projectMVPBlockTemplateLines',
  props<
    {
      projectMVPBlockTemplateLines: ProjectMVPBlockTemplateLine[];
    } & ActionBase<ProjectMVPBlockTemplateLine[]>
  >()
);

export const addProjectMVPBlockTemplateLinesResolved = createAction(
  '[ProjectMVPBlockTemplateLines] Add projectMVPBlockTemplateLines resolved',
  props<{ addedProjectMVPBlockTemplateLines: ProjectMVPBlockTemplateLine[] }>()
);
